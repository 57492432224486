


























































































































































































// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import * as Model from "@gigalot/data-models";
import lodash from "lodash";
const Subscriber = require("@jetblack/graphql-reconnect-client");
import { createClient } from "@/helpers/graphql-ws-rtc-adapter";
import FeedDeliverySummary from "@/views/FeedDeliverySummary.vue";

@Component({
  components: {
    FeedDeliverySummary,
  },
})
export default class FeedMixer extends Vue {
  name = "FeedMixer";
  loading = true;
  search = "";
  feedPlan: any = {};
  MixingSchedule: any = {};
  tempprogress = 20;
  bufferValue = 100;
  tableKey = 0;
  snack = false;
  snackColor = "";
  snackText = "";
  errorText = "";
  errorDialog = false;
  addNewMixDialog = false;
  addNewKraalDialog = false;
  rations: Model.Ration[] = [];
  loaders: Model.Loader[] = [];
  selectedLoader: any = 0;
  selectedLoadType: string = "Mix";
  selectedRation: any = "";
  selectedFeedAllocation: any = {};
  selectedMix: any = {};
  fixedHeader = [{}];
  availableKraals: Model.MixingScheduleItemAllocation[] = [];

  get formattedKraals() {
    return this.availableKraals.map((kraal) => ({
      ...kraal,
      formattedText: `${kraal.kraalID} (${kraal.totalFeedAllocated}kg) - Session ${kraal.session}`,
    }));
  }

  get themeClass(): string {
    return this.$store.state.lightDarkMode;
  }

  headers = [
    {
      text: "Sequence",
      value: "sequence",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 50,
    },
    {
      text: "Scheduled Time",
      value: "time",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 20,
    },
    {
      text: "Mixer",
      value: "mixerGuid",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 50,
    },
    {
      text: "Operator",
      value: "operator",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 50,
    },
    {
      text: "Ration",
      value: "ration",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 50,
    },
    {
      text: "Capacity",
      value: "mixerCapacity",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 100,
    },
    {
      text: "Type",
      value: "loadType",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 100,
    },
    { text: "", value: "row-operations", width: 20 },
    { text: "", value: "data-table-expand", width: 20 },
  ];
  expanded = [];
  expandAllIssues(items: any, status: any) {
    if (status) {
      this.expanded = items;
    } else {
      this.expanded = [];
    }
  }
  mounted() {
    this.$store.dispatch("user/addFirebaseCallback", this.getFeedMixer);
    this.$store.dispatch("user/addFirebaseCallback", this.getFeedMixerSocket);
    this.rations = lodash.cloneDeep(this.$store.getters["storage"]().Rations) as Model.Ration[];
    this.loaders = lodash.cloneDeep(this.$store.getters["storage"]().Loaders) as Model.Loader[];
  }
  get moment() {
    return this.$store.state.moment;
  }
  destroyed() {
    if (this.graphQLUnsubscribe) {
      this.graphQLUnsubscribe();
    }
    if (this.graphQLSubscriber) {
      this.graphQLSubscriber();
    }
  }
  urlws = "wss://pi.gigalot.systems:7778/api/ws";
  options = {};

  subscriptions = `subscription($guid:String!){
        mixingSchedule(guid:$guid){
          typename
          guid
          date
          MixingScheduleItems{
            typename
            guid
            mixerGuid
            mixerCapacity
            ration
            rationGuid
            loadType
            allocated
            time
            completed
            operator
            feedAllocations{
              typename
              kraalID
              kraalGuid
              rationGuid
              rationCode
              totalFeedAllocated
              totalFeedDelivered
              feedingjobDone
              active
              session
              percentage
            }
            }
          }
        }`;

  variables = { guid: this.$store.state.user.location.guid };
  operationName = null;

  graphQLSubscriber: any = undefined;
  graphQLUnsubscribe: any = undefined;

  getLoaderName(guid: string) {
    let tractor = this.loaders.find((loader) => {
      if (loader.guid === guid) return loader;
    });
    if (tractor) return tractor.tractor.registrationNumber;
  }

  async getFeedingKraals(item: Model.MixingScheduleItem) {
    try {
      this.selectedMix = item;
      console.log("MixingSchedule()");
      this.subloading = true;
      let gql = `query($guid:String!,$ration:String!,$tractor:String!){
        feedingKraals(guid:$guid,ration:$ration,tractor:$tractor){
            typename
            kraalID
            kraalGuid
            rationGuid
            rationCode
            totalFeedAllocated
            active
            session
            percentage
         }
        }`;

      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { guid: this.$store.state.user.location.guid, ration: item.rationGuid, tractor: item.mixerGuid },
      });
      this.availableKraals = json.data.feedingKraals;
      this.addNewKraalDialog = true;
      this.subloading = false;
    } catch (err) {
      console.log("getFeedMixer -> error: " + err);
    }
  }

  async getFeedMixerSocket() {
    if (this.$store.state.useP2PConn) {
      await this.getOnlineFeedMixerSocket();
    } else {
      await this.getOnsiteFeedMixerSocket();
    }
  }

  async getOnsiteFeedMixerSocket() {
    this.graphQLSubscriber = Subscriber.graphQLReconnectingSubscriber(
      this.urlws,
      this.options,
      (error: any, subscribe: any) => {
        try {
          if (!(error || subscribe)) {
            // Normal closure.
            console.log("connected to socket");
            this.$store.state.wsDialogVisible = false;
            return;
          }
          if (error) {
            console.log("error connecting to socket");
            if (error.event.type === "close") {
            } else {
              this.$store.state.wsDialogVisible = true;
              console.error(error);
            }
          }
          console.log("socket connected");
          this.graphQLUnsubscribe = subscribe(this.subscriptions, this.variables, this.operationName, (error: any, data: any) => {
            if (!(error || subscribe)) {
              console.log("subscribe success");
              // Normal closure
              this.$store.state.wsDialogVisible = false;
              return;
            }
            if (error) {
              console.log("error subscribing");
              if (error.event.type === "close") {
              } else {
                this.$store.state.wsDialogVisible = true;
                console.error(error);
              }
            }
            this.$store.state.wsDialogVisible = false;
            console.log("receiving data");
            this.MixingSchedule = data.mixingSchedule;
            if (this.MixingSchedule.MixingScheduleItems?.length > 0) this.loading = false;
          });
        } catch (err) {}
      },
      15000,
      3,
      "graphql-ws"
    );
  }

  async getOnlineFeedMixerSocket() {
    let variables = { guid: this.$store.state.user.location.guid, user: this.$store.state.user.user.uid };

    const subscribe = (subscriptions: string, variables: any, callback: (error: any, data: any) => Promise<void>) => {
      const client = createClient();
      let unsubscribe = client?.subscribe(
        {
          query: subscriptions,
          variables: variables,
        },
        {
          next: (message: any) => callback(undefined, message.data),
          error: (err: any) => {
            throw err;
          },
          complete: () => console.log("subscription completed"),
        }
      );
      return unsubscribe;
    };

    this.graphQLUnsubscribe = subscribe(this.subscriptions, variables, async (error: any, data: any) => {
      this.$store.state.wsConnected = true;
      if (!(error || subscribe)) {
        console.log("subscribe success");
        this.$store.state.wsDialogVisible = false;
        // Normal closure
        return;
      }
      if (error) {
        console.log("error subscribing");

        this.$store.state.wsConnected = false;
        if (error.event.type === "close") {
        } else {
          this.$store.state.wsDialogVisible = true;
          console.error(error);
          //    throw error;
        }
      }
      this.$store.state.wsDialogVisible = false;
      this.$store.state.wsConnected = true;
      console.log("almost there");

      console.log(data);
      this.MixingSchedule = data.mixingSchedule;
      this.loading = false;
    });
  }

  subloading = false;
  getTotalAdded(item: Model.MixingScheduleItem) {
    return item.feedAllocations.reduce((accumulator, i) => {
      return accumulator + i.totalFeedAllocated;
    }, 0);
  }

  getColor(kraalId: string) {
    let itemPlan = lodash.find(this.feedPlan.FeedingPlanItems, { kraalId: kraalId }); //TODO: replace kraal ID with Kraal guid
    return itemPlan.currentRation.colour;
  }

  getSessionColor(session: number) {
    switch (session) {
      case 0:
        return "black";
      case 1:
        return "#80CBC4";
      case 2:
        return "#009688";
      case 3:
        return "#00695C";
      case 4:
        return "#004D40";
    }
    //TODO: make session colors from palette
  }

  async makeNewMix() {
    //check if all fields are valid
    let url = `${this.$store.getters["backendUrl"]()}/newMix`;
    let jwt = await this.$store.dispatch("user/getOnlineIdToken", undefined, {
      root: true,
    });

    let data = {
      capacity: parseInt(this.selectedLoader.trailer.maxCapacity),
      mixerGuid: this.selectedLoader,
      rationCode: this.selectedRation.name,
      rationGuid: this.selectedRation.guid,
      type: this.selectedLoadType,
    };
data.mixerGuid.trailer.maxCapacity = parseInt(data.mixerGuid.trailer.maxCapacity);
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: `mutation newMix($guid:String!, $mixInfo: MixInfoInput!) {
              newMix(guid:$guid, input: $mixInfo) 
        }`,
          variables: { guid: this.$store.state.user.location.guid, mixInfo: data },
        },
        { root: true }
      );
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      //close dialog
      this.addNewMixDialog = false;
    }
  }

  async getFeedMixer() {
    try {
      console.log("MixingSchedule()");
      this.loading = true;
      let gql = `query($guid:String!){
        MixingSchedule(guid:$guid){
          typename
          guid
          date
          MixingScheduleItems{
            typename
            guid
            mixerGuid
            mixerCapacity
            ration
            rationGuid
            loadType
            allocated
            time
            completed
            operator
            feedAllocations{
              typename
              kraalID
              kraalGuid
              rationGuid
              rationCode
              totalFeedAllocated
              totalFeedDelivered
              feedingjobDone
              active
              session
              percentage
            }
          }
        }
        FeedPlan(guid:$guid) {
          FeedingPlanItems{
            kraalId
            numberAnimals
            currentRation{
              colour
            }
            }
          }
        }`;

      let json = await this.$store.dispatch("graphQl", { gql, variables: { guid: this.$store.state.user.location.guid } });
      this.MixingSchedule = json.data.MixingSchedule;
      this.feedPlan = json.data.FeedPlan;
      //  this.rationPlan = json.data.RationPlan;
    } catch (err) {
      console.log("getFeedMixer -> error: " + err);
    }
    if (this.MixingSchedule.MixingScheduleItems?.length > 0) this.loading = false;
  }

  getKraalType(guid: string) {
    let kraals = this.$store.getters[`storage`]().Kraals as Model.Kraal[];
    let kraal = lodash.find(kraals, { guid: guid });
    if (kraal) return kraal.kraalType;
  }

  page = 1;
  forceRerender() {
    let pageHolder = this.page;
    console.log(pageHolder);
    this.tableKey += 1;
    this.page = pageHolder;
    console.log(pageHolder);
  }

  addNewKraaltoSchedule() {
    //Add the maximum amount available to kraal and mix schedule
    if (this.selectedFeedAllocation && this.selectedMix) {
      let avalableCapacity = this.selectedMix.mixerCapacity - this.getTotalAdded(this.selectedMix);
      if (avalableCapacity === 0) {
        this.addNewKraalDialog = false;
        return;
      }
      if (this.selectedFeedAllocation.totalFeedAllocated > avalableCapacity) this.selectedFeedAllocation.totalFeedAllocated = avalableCapacity;
      this.selectedFeedAllocation.feedingjobDone = false;
      this.selectedFeedAllocation.totalFeedDelivered = 0;
      delete this.selectedFeedAllocation.formattedText;
      this.selectedMix.feedAllocations.push(this.selectedFeedAllocation);
      //Save
      this.updateServerItem(this.selectedMix);
    }
    this.selectedFeedAllocation = {};
    this.addNewKraalDialog = false;
  }

  removeKraalFromAllocation(feedkraal: Model.MixingScheduleItemAllocation, item: Model.MixingScheduleItem) {
    lodash.remove(item.feedAllocations, (o) => {
      return o === feedkraal;
    });
    this.forceRerender();
    this.updateServerItem(item);
    //TODO: remove feedkraal from item
    //save item back to server (mutation)
  }
  deleteMix(item: Model.MixingScheduleItem) {
    this.MixingSchedule.MixingScheduleItems.splice(this.MixingSchedule.MixingScheduleItems.indexOf(item), 1);
    this.updateServer();
    //save mixSchedule back to server
  }

  cancel() {
    this.snack = true;
    this.snackColor = "blue lighten-1";
    this.snackText = "Canceled";
  }
  close() {}
  save(item: any) {
    this.updateServer();
  }

  async updateServer() {
    try {
      console.log("updateServer()");

      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: `mutation mutateMixSchedule($guid:String!, $input: MixingScheduleInput!) {
            mutateMixSchedule(guid:$guid, input: $input) {
          typename
          guid
          date
          MixingScheduleItems{
            typename
            guid
            mixerGuid
            mixerCapacity
            ration
            rationGuid
            loadType
            allocated
            time
            operator
            feedAllocations{
              typename
              kraalID
              kraalGuid
              rationGuid
              rationCode
              totalFeedAllocated
              totalFeedDelivered
              feedingjobDone
              active
              session
              percentage
            }
          }
        }
        }`,
          variables: { guid: this.$store.state.user.location.guid, input: this.MixingSchedule },
        },
        { root: true }
      );

      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    } catch (err) {
      console.log(err);
      this.errorDialog = true;
      this.errorText = `Error saving to server ${err}`;

      throw err;
    }
  }

  async updateServerItem(item: Model.MixingScheduleItem) {
    try {
      console.log("updateServer()");

      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: `mutation addMixSchedule($guid:String!, $input: MixingScheduleItemInput!) {
            addMixSchedule(guid:$guid, input: $input) 
        }`,
          variables: { guid: this.$store.state.user.location.guid, input: item },
        },
        { root: true }
      );

      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    } catch (err) {
      console.log(err);
      this.errorDialog = true;
      this.errorText = `Error saving to server ${err}`;

      throw err;
    }
  }
}
