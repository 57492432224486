import LogRocket from 'logrocket';
LogRocket.init('3gwxd3/feeder-kgg5a');

export function identify(user:any){
// This is an example script - don't forget to change it!
LogRocket.identify(user.uid, {
    name: user.displayName,
    email: user.email,
    // Add your own custom user variables here, ie:
    subscriptionType: 'pro'
  });
}

