































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import { mapMutations } from "vuex";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";
import { validationMixin } from 'vuelidate'
import {required, minLength, alphaNum, integer, between } from 'vuelidate/lib/validators';
const vuexMapFields = require("vuex-map-fields");
const mapFields = vuexMapFields.mapFields;
import store from "@/store";
import { CostRation } from "@gigalot/data-models";
import { changeTrackingComponentFragment } from "@/helpers/gql";
function duplicated(value:string){
  const cm = store.getters["storage"]().ration
  const rations = store.getters["storage"]().Rations.filter((m: any) => m.guid !== cm.guid);
  const f = lodash.find(rations,{name:value});
  return f ? false : true;
} 

@Component({
  components: {
    DimssaButton,
  },
  mixins: [validationMixin],
  validations : { 
    name: { required, minLength: minLength(3), duplicated },
    description: {required},
    rationMinMix: {required, between: between(0,20000)},
    jaylorMinMix: {required, between: between(0,20000)},
    useJobWeightBool: {required},
    dryMatterFactor: {required, between: between(0,1)},
  },
  computed: {
    ...mapFields([
      "ration.name",
      "ration.description",
      "ration.rationMinMix",
      "ration.jaylorMinMix",
      "ration.useJobWeightBool",
      "ration.minMixTime",
      "ration.active",
      "ration.dryMatterFactor",
      "ration.rationCost",
      "ration.challengePercentage"
    ]),
  },
})
export default class Ration extends Vue {
    tableKey = 0;
    
  get ration(): Models.Ration {
    return this.$store.getters["storage"]().ration as Models.Ration;
  }
  tempAutoCost: boolean = false;
tempCost: number | "" = "";
  addCost() {
    if (this.costRation) {
      this.costRation.guid = this.ration.guid;
      this.$store.commit("snackbar", { visible: false });
      if (this.tempCost !== "" || this.tempAutoCost) {
        console.log(this.tempSince);
        let historyItemIndex = lodash.findIndex(this.costRation.history, { since: this.tempSince });
        if (historyItemIndex >= 0) {
          this.costRation.history[historyItemIndex].value = this.tempAutoCost || this.tempCost === "" ? undefined : this.tempCost;
          this.costRation.history[historyItemIndex].auto = this.tempAutoCost;
       
        } else
          this.costRation.history.push({
            value: this.tempAutoCost || this.tempCost === "" ? undefined : this.tempCost,
            since: this.tempSince,
            auto: this.tempAutoCost,
            per: 1.0,
            unit: "tonne",
          });
      } else {
        this.$store.commit("snackbar", { message: `Invalid Cost`, timeout: 5000 });
      
      }
      this.$forceUpdate();
    } else throw Error("Tried to add cost to costRation which doesn't exist!");
  }
getTotal(components: Models.RationComponent[]){
  let sum =0;
  for (let i=0;i<components.length;i++){
    sum += components[i].percentage;
  }
  return sum.toFixed(1);
}
  addDMF() {
    if (this.dmf) {
      this.dmf.componentGuid = this.ration.guid;
      this.$store.commit("snackbar", { visible: false });
      if (this.dmf.dryMatterFactor !== 0) {
        console.log(this.tempSinceDMF);
        let historyItemIndex = lodash.findIndex(this.dmf.history, { since: this.tempSinceDMF });
        if (historyItemIndex >= 0) {
          this.dmf.history[historyItemIndex].value = this.dmf.dryMatterFactor;
  
          //this.costRation.history[historyItemIndex].per = this.tempPer;
        } else
          this.dmf.history.push({
            value: this.dmf.dryMatterFactor,
            since: this.tempSinceDMF,
          });
      } else {
        this.$store.commit("snackbar", { message: `Invalid Dry Matter`, timeout: 5000 });
       
      }
      this.save();
      this.$forceUpdate();
    }
  }
  
  async saveCost(cost: Models.CostRation) {
    if (!cost) throw Error("saveCost cost is undefined");
    let costClone = lodash.cloneDeep(cost);
    costClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
    let json = await this.$store.dispatch(
      "graphQl",
      {
        gql: `mutation costRation($guid: String!, $cost: CostRationInput!) {
                costRation(guid: $guid, cost: $cost) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
        variables: { guid: this.$store.state.user.location.guid, cost: costClone },
      },
      { root: true }
    );
    let costRation = this.costRation as Models.CostRation;
    costRation.changeTracking = json.data.costRation;
    //await this.$store.dispatch("data/set", { objectStore: "CostRation", items: [lodash.cloneDeep(costRation)] });
  }


  copyItem(item: any) {
    this.tempSince = item.since;
    this.tempCost = item.value;
    this.tempAutoCost = item.auto;
  }

  // copyDMF(item: any) {
  //   this.tempSince = item.since;
  //   this.tempCost = item.value;
  //   this.tempAutoCost = item.auto;
  // }

  deleteItem(item: any) {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure?",
      yesAction: () => {
        if (!this.costRation) return;
        const index = this.costRation.history.indexOf(item);
        this.costRation.history.splice(index, 1);
      },
    });
  }

  deleteDMF(item: any) {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure?",
      yesAction: () => {
        if (!this.dmf) return;
        const index = this.dmf.history.indexOf(item);
        this.dmf.history.splice(index, 1);
      },
    });
  }

  get components() {
    console.log(this.ration.rationComponents);
    return this.ration.rationComponents;
  }

  editComponent(index: number) {
    this.editComponentIndex = index;
  }

  confirmEditComponent(component: any, index: number) {
    this.ration.rationComponents[index] = component;
    this.$store.commit("store", { ration: this.ration });
    this.editComponentIndex = null;
  }

  editComponentIndex: number | null = null;

  addComponent() {
    let component: Models.RationComponent = new Models.RationComponent();
    component.sequence = this.ration.rationComponents.length + 1;
    component.code = this.ration.name;
    this.ration.rationComponents.push(component);
    this.$store.commit("store", { ration: this.ration });
    this.editComponentIndex = this.ration.rationComponents.length - 1;
  }

  removeComponent(index:number){
    lodash.remove(this.components,this.components[index]);
    this.forceRerender();
  }
    forceRerender() {
    this.editComponentIndex = -1;
    this.editComponentIndex = -2;
  }
changed: boolean = false;

cancel() {
    if (this.changed){
      this.$store.commit("popup/displayYesNo", {
      message: "Discard changes?",
      yesAction: () => {
        this.$store.commit("updateField", {
          path: "ration",
          value: {}
        });
        this.$router.go(-1);
      }
    });
    }
    else {
      this.$router.go(-1);
    }
 }

  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to save?",
      yesAction: async () => {
        try {
          console.log(this.$store.getters["storage"]().ration);
          let rationClone = lodash.cloneDeep(this.$store.getters["storage"]().ration) as Models.Ration;
          //fix component names if name change occured
          for (let component of rationClone.rationComponents) {
            if (rationClone.name) component.code = rationClone.name;
          }
          console.log(this.costRation);
          rationClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          if (this.dmf)
            rationClone.dryMatterFactor = this.dmf;
          if (this.costRation) 
            this.costRation.guid = this.ration.guid;
          rationClone.rationCost = this.costRation;
          
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation ration($guid: String!, $ration: RationInput!) {
                ration(guid: $guid, ration: $ration)
              }`,
              variables: {
                guid: this.$store.state.user.location.guid,
                ration: rationClone,
              },
            },
            { root: true }
          );
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/displayOk", {
            message: "Error occured while trying to save to server",
          });
          throw err;
        }
        await this.rations(false);
      },
    });
  }

  async rations(push: boolean = true) {
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.rations,
          variables: { guid: this.$store.state.user.location.guid },
        },
        { root: true }
      );

      this.$store.commit("updateField", {
        path: "Rations",
        value: json.data.Rations,
      });
    } catch (err) {
      console.log(err);
      this.$store.commit("popup/displayOk", {
        message: `Error: ${err}`,
      });
    }
    if (push) this.$router.push({ path: "list/rations" });
  }

  mounted() {
    if (this.ration.rationCost)
      this.costRation = this.ration.rationCost
    if (this.ration.dryMatterFactor)
      this.dmf = this.ration.dryMatterFactor;
  }

  destroyed() {}
  dmf: Models.DrymatterFactor = new Models.DrymatterFactor()
  costRation: Models.CostRation = new CostRation()

get costRations(): Models.CostRation[] {
  throw Error("fix costRation()");
  //return this.$store.getters["storage"]().costRations;
}
get themeClass(): string {
    return this.$store.state.lightDarkMode;
  }

get moment() {
    return this.$store.state.moment;
  }

tempSince: number = this.moment(this.moment(Date.now()).format("YYYY-MM-DD")).valueOf();
tempSinceDMF: number = this.moment(this.moment(Date.now()).format("YYYY-MM-DD")).valueOf();
sinceDateMenu: boolean = false;
sinceDateMenuDMF: boolean = false;

get sinceDate() {
  return this.moment(new Date(this.tempSince)).format("YYYY-MM-DD");
}
get sinceDateDMF() {
  return this.moment(new Date(this.tempSinceDMF)).format("YYYY-MM-DD");
}

set sinceDate(sinceDate: string) {
  this.tempSince = this.moment(sinceDate).valueOf();
}

set sinceDateDmf(sinceDate: string) {
  this.tempSinceDMF = this.moment(sinceDate).valueOf();
}
}
