var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":"ready"},on:{"onclick":function($event){return _vm.$router.push({ name: 'outbox' })}}},[_vm._v("OUTBOX ("+_vm._s(_vm.cribScoresToUpload)+")")])],1)],1),_c('v-card-text',[(_vm.loading)?_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto",attrs:{"type":"table","tile":false,"loading":_vm.loading}}):_vm._e(),(!_vm.loading)?_c('v-data-table',{key:_vm.tableKey,class:_vm.themeClass,attrs:{"headers":_vm.headers,"footer-props":_vm.customFooterProps,"items":_vm.cribScores,"item-key":"kraalId","items-per-page":50,"loading":_vm.loading,"Loading-text":"Loading... Please wait","search":_vm.search,"sort-by":"sequence"},on:{"click:row":_vm.selectRow},scopedSlots:_vm._u([{key:"item.kraalId",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.kraalId))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(_vm._s(item.status))])],1),_c('v-col',[(item._send === 'receivedByServer')?_c('v-icon',{staticStyle:{"display":"flex","align-items":"center","width":"100%"},attrs:{"color":"lime"}},[_vm._v("mdi-check")]):(item._send === 'failedToSend')?_c('v-icon',{staticStyle:{"display":"flex","align-items":"center","width":"100%"},attrs:{"size":"24","color":"red"},on:{"click":function($event){return _vm.save(item)}}},[_vm._v("mdi-close")]):(item._send === 'sending')?_c('v-progress-circular',{staticStyle:{"margin":"1px","display":"flex","align-items":"center","width":"100%"},attrs:{"size":"24","indeterminate":"","color":"blue"}}):_vm._e()],1)],1)]}},{key:"item.ration",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.color.toLowerCase(),"dark":""}},[_vm._v(_vm._s(item.ration))])]}},{key:"item.call",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.call,"large":""},on:{"update:returnValue":function($event){return _vm.$set(item, "call", $event)},"update:return-value":function($event){return _vm.$set(item, "call", $event)},"save":_vm.save,"cancel":_vm.cancel,"open":function($event){return _vm.open(item)},"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","outlined":"","autofocus":"","type":"number"},model:{value:(item.call),callback:function ($$v) {_vm.$set(item, "call", _vm._n($$v))},expression:"item.call"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.call.toFixed(1))+" ")])]}},{key:"item.score",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.score,"large":""},on:{"update:returnValue":function($event){return _vm.$set(item, "score", $event)},"update:return-value":function($event){return _vm.$set(item, "score", $event)},"save":_vm.save,"cancel":_vm.cancel,"open":function($event){return _vm.open(item)},"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-btn',{on:{"click":function($event){return _vm.doAutoAdjustment(0,item.call,item)}}},[_vm._v(_vm._s(_vm.getAlias(0)))]),_c('v-btn',{on:{"click":function($event){return _vm.doAutoAdjustment(1,item.call,item)}}},[_vm._v(_vm._s(_vm.getAlias(1)))]),_c('v-btn',{on:{"click":function($event){return _vm.doAutoAdjustment(2,item.call,item)}}},[_vm._v(_vm._s(_vm.getAlias(2)))]),_c('v-btn',{on:{"click":function($event){return _vm.doAutoAdjustment(3,item.call,item)}}},[_vm._v(_vm._s(_vm.getAlias(3)))]),_c('v-btn',{on:{"click":function($event){return _vm.doAutoAdjustment(4,item.call,item)}}},[_vm._v(_vm._s(_vm.getAlias(4)))]),_c('v-btn',{on:{"click":function($event){return _vm.doAutoAdjustment(5,item.call,item)}}},[_vm._v(_vm._s(_vm.getAlias(5)))])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.getAlias(item.score))+" ")])]}},{key:"item.sequence",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.getChevronIcon(item)))]),_vm._v(" "+_vm._s(_vm.getAllocationAmount(item))+" kg ")]}},{key:"item.amountFed",fn:function(ref){
var item = ref.item;
return [(_vm.loadingHistory)?_c('span',[_vm._v("Downloading History")]):_vm._e(),(_vm.loadingHistory)?_c('v-skeleton-loader',{ref:"skeletonHistory",staticClass:"mx-auto",attrs:{"type":"list-item","tile":false,"loading":_vm.loadingHistory}}):_vm._e(),(!_vm.loadingHistory)?_c('v-sparkline',{attrs:{"value":_vm.getcribHistoryFed(item.kraalId),"color":"rgba(255, 255, 255, .7)","height":"60","padding":"10","stroke-linecap":"round","smooth":"","label-size":"9","show-labels":"","auto-draw":"","gradient-direction":"bottom","gradient":_vm.gradient}}):_vm._e()]}}],null,false,1418954697)}):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.errorDialog),callback:function ($$v) {_vm.errorDialog=$$v},expression:"errorDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Error saving to server")]),_c('v-card-text',[_vm._v(_vm._s(_vm.errorText))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.errorDialog = false}}},[_vm._v("OK")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(_vm._s(_vm.snackText))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }